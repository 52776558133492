import axios from "@/config/http";
export const popularFactoryApi = {
    // 热门工厂列表
    getHotFactory() {
        return axios.get("/popular/factory/listPopularFactory")
    },
    // 批量新增热门工厂
    addHotFactory(data){
        return axios.post("/popular/factory",data)
    },
    // 编辑热门工厂
    editHotFactory(data){
        return axios.put("/popular/factory",data)
    },
    // 删除热门工厂
    delHotFactory(params){
        return axios.delete("/popular/factory",{params})
    },
    // 热门工厂-工厂搜索
    searchHotFactory(params){
        return axios.get("/popular/factory/listFactory",{params})
    }
}