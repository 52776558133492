<template>
  <div>
    <div class="bottom-container">
      <a-button class="btn" @click="addHotFactory"> 添加 </a-button>
      <a-button
        type="primary"
        @click="handleServe"
        class="btn"
        :loading="serveLoading"
      >
        保存
      </a-button>
      <a-table
        :columns="columns"
        :pagination="false"
        :data-source="data"
        :scroll="{ y: 375 }"
        :loading="loading"
      >
        <span slot="factoryName_1"><span class="require">*</span>工厂名称</span>
        <span slot="periodValidity_1"
          ><span class="require">*</span>有效期</span
        >
        <template slot="factoryName" slot-scope="text, record">
          <a-select
            v-if="!record.id"
            style="width: 150px"
            show-search
            v-model="record.factoryName"
            :not-found-content="null"
            @search="getFactoryName(record.factoryName)"
            @change="(e) => handleChange(e, record)"
          >
            <a-select-option
              v-for="(item, index) in factoryNameList"
              :key="index"
            >
              {{ item.factoryName }}
            </a-select-option>
          </a-select>
          <div v-else>
            {{ record.factoryName }}
          </div>
        </template>
        <template slot="factoryLinkman" slot-scope="text, record">
          <div>{{ record.factoryLinkman }}</div>
        </template>
        <template slot="periodValidity" slot-scope="text, record">
          <a-date-picker
            v-model="record.periodValidity"
            valueFormat="YYYY-MM-DD"
            :disabled-date="disabledDate"
          />
        </template>
        <template slot="validity" slot-scope="text, record">
          <div>{{ record.validity }}</div>
        </template>
        <template slot="remark" slot-scope="text, record">
          <a-input v-model="record.remark" placeholder="请输入"></a-input>
        </template>
        <template slot="option" slot-scope="text, record, index">
          <span
            @click="delFactory(record, index)"
            style="color: rgb(255, 70, 0); cursor: pointer"
          >
            删除
          </span>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "factoryName",
    key: "factoryName",
    ellipsis: true,
    slots: { title: "factoryName_1" },
    scopedSlots: { customRender: "factoryName" },
  },
  {
    title: "工厂联系人",
    dataIndex: "factoryLinkman",
    ellipsis: true,
    key: "factoryLinkman",
    scopedSlots: { customRender: "factoryLinkman" },
  },
  {
    dataIndex: "periodValidity",
    ellipsis: true,
    key: "periodValidity",
    slots: { title: "periodValidity_1" },
    scopedSlots: { customRender: "periodValidity" },
  },
  {
    title: "是否过期",
    dataIndex: "validity",
    ellipsis: true,
    key: "validity",
  },
  {
    title: "备注",
    dataIndex: "remark",
    ellipsis: true,
    key: "remark",
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    key: "option",
    dataIndex: "option",
    width: "150px",
    scopedSlots: { customRender: "option" },
  },
];
import { setKey } from "@/utils/utils.js";
import { popularFactoryApi } from "@/api/popularFactory.js";
import moment from "moment";
export default {
  data() {
    return {
      columns,
      searchText: {
        factoryName: null, //用户名
        factoryLinkman: null, //手机号
        validity: null, //联系人
        remark: null, //是否付款
        factoryPhonenumber2: null, //付款时间
      },
      isPayOption: [
        { label: "是", value: 1 },
        { label: "否", value: 0 },
      ],
      factoryNameOption: [],
      factoryNameList: [],
      loading: false,
      serveLoading: false,
      factoryReset: [],
      data: [],
    };
  },
  methods: {
    disabledDate(current) {
      // 只能选择今天之后的时间
      return current && current < moment().endOf("day");
    },
    async getBaseData() {
      this.loading = true;
      try {
        let res = await popularFactoryApi.getHotFactory();
        this.data = setKey(res.data);
        this.factoryReset = setKey(res.data);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    addHotFactory() {
      if (this.data.length >= 10) {
        return this.$message.warn("热门工厂已存在10条，不可新增！");
      }
      this.data.unshift({
        factoryName: null,
        factoryLinkman: null,
        periodValidity: null,
        validity: null,
        remark: null,
      });
    },
    handleChange(e, record) {
      record.factoryLinkman = this.factoryNameList[e].factoryLinkman;
      record.factoryName = this.factoryNameList[e].factoryName;
      record.factoryId = this.factoryNameList[e].factoryId;
    },
    async getFactoryName(factoryName) {
      const res = await popularFactoryApi.searchHotFactory({
        factoryName: factoryName,
      });
      this.factoryNameList = res.data;
    },
    async handleServe() {
      this.data.forEach((item) => {
        if (!item.factoryName) {
          this.$message.error("请填写工厂名称");
          throw new Error("请填写工厂名称");
        }
        if (!item.periodValidity) {
          this.$message.error("请输入有效期");
          throw new Error("请输入有效期");
        }
      });
      this.serveLoading = true;
      const res = await popularFactoryApi.addHotFactory(this.data);
      if (res.success) {
        this.serveLoading = false;
        this.getBaseData();
      } else {
        this.serveLoading = false;
        this.$message.error(res.errorMsg);
      }
    },
    async delFactory(record, index) {
      const that = this;
      this.$confirm({
        title: "是否确认删除该条热门工厂",
        cancelText: "取消",
        okText: "确定",
        async onOk() {
          if (record.id) {
            const res = await popularFactoryApi.delHotFactory({
              id: record.id,
            });
            if (res.success) {
              that.data.splice(index, 1);
            } else {
              that.$message.error(res.errorMsg);
              throw new Error(res.errorMsg);
            }
          } else {
            that.data.splice(index, 1);
          }
        },
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    const result = this.data.find((item) => {
      return !item.factoryLinkman||!item.periodValidity||!item.factoryName
    });
    if (result) {
      this.$confirm({
        type: "warning",
        title: "当前列表未完成编辑，是否继续?",
        cancelText: "取消",
        okText: "继续",
        onCancel() {
          next();
        },
      });
    } else {
      next();
    }
  },
  mounted() {
    this.getBaseData();
    this.getFactoryName();
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .search-item {
    width: 26%;
    text-align: right;
    .button-item {
      margin-left: 10px;
      &:nth-child(2) {
        background-color: #ff4600;
        color: #fff;
      }
    }
  }
  .right-btn {
    width: 100%;
  }
}
.ant-table-wrapper {
  // margin-top: 20px;
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}
.require {
  color: rgb(255, 70, 0);
}
.btn {
  margin-bottom: 20px;
  margin-right: 20px;
}
</style>
